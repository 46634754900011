<template>
  <div>
    <div class="text-center">
      <h2 class="fw-bold highlighted-color1">
        <span>Trocar senha</span>
      </h2>
    </div>
    <p class="text-center">Escolha uma nova senha</p>

    <div class="col-md-6 mx-md-auto">
      <form
        class="default-form my-5"
        @submit.prevent="submit"
      >
       <div class="form-group mb-3">
         <label class="form-label fw-bold">E-mail</label>
         <input
          type="email"
          class="form-control"
          label="Endereço de email"
          placeholder="email@email.com.br"
          v-model.trim="$v.form.email.$model"
        />
       </div>

        <div class="form-group mb-3">
          <label class="form-label fw-bold">Senha</label>
          <input type="password" class="form-control" placeholder="Insira sua senha" v-model.trim="$v.form.password.$model" autocomplete="new-password" />
        </div>
        <div class="form-group mb-3">
          <label class="form-label fw-bold">Confirme a senha</label>
          <input type="password" class="form-control" placeholder="Confirme sua senha" v-model.trim="$v.form.password_confirmation.$model" autocomplete="new-password" />
        </div>

        <div class="text-center mt-5">
          <Button
            class="btn-primary btn-lg"
            :loading="loader"
            :disabled="$v.$invalid"
            @click="submit"
          >
            Trocar senha
          </Button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

import AuthService from '@/modules/auth/services/auth-service'
import NotificationService from '@/modules/notifications/services/notifications-service'
import { required, email } from 'vuelidate/lib/validators'
import { mapState } from 'vuex'

import Button from '@/components/common/Button/Button'

export default {
  components: {
    Button
  },
  data () {
    return {
      form: {
        email: '',
        password: '',
        password_confirmation: '',
        token: this.$route.params.token
      },
      loader: false
    }
  },
  metaInfo () {
    return {
      title: 'Trocar senha'
    }
  },
  validations () {
    return {
      form: {
        email: { required, email },
        password: { required },
        password_confirmation: { required },
        token: { required }
      }
    }
  },
  computed: {
    ...mapState('auth', {
      authenticated: state => state.authenticated
    })
  },
  methods: {
    async submit () {
      this.loader = true
      try {
        await AuthService.sendResetPassword(this.form)
        const notification = {
          notification: {
            type: 'success',
            content: 'Senha alterada com sucesso'
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
        this.$router.push('/')
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    }
  }
}
</script>
